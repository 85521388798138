import React, { Component } from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { Formulario } from './app/components/Formulario';
import { Exitoso } from './app/components/Exitoso';

class App extends Component {

  render() {
    return (

      <BrowserRouter>
        <div>
          <Routes>
          <Route exact={true} path='/' element={<Formulario />} />
          <Route exact={true} path='/confirmacion-exitosa' element={<Exitoso />} />
          </Routes>
        </div>
      </BrowserRouter>
    

    );
  }
}

export default App;
