import { ContratanteProveedor } from '../../../reducer/Contratante/ContratanteProveedor';
import { Contratante } from './Contratante/Contratante';
import { Informacion } from './Informacion/Informacion'
import { Nomina } from './Nomina/Nonima';

export const Contenido = () => {
  return (
    <main className='container'>
      <ContratanteProveedor>
        <Informacion />
        <Contratante />
        <Nomina />
      </ContratanteProveedor>
    </main>
  );
}